import React from 'react';
//import { useWindowSize, useWindowWidth, useWindowHeight } from '@react-hook/window-size'

export const GetFeaturedImage = ( images ) => {

    if( images && ("0" in images) ) {

        const { source_url } = images['0'];
        
        return (
            <img 
                src={source_url} 
                className="motd-image"
                alt="Message of the day"
            />
        )
    } else {
        return null
    }
}

export const ImageAndTextTemplate = (item) => {
    //const [width, height] = useWindowSize()
//    const onlyWidth = useWindowWidth()
//    const onlyHeight = useWindowHeight()

    // const { _embedded: { `${wp:featuredmedia}` } = { `${wp:featuredmedia}`: [] } } = item;
    const { _embedded } = item;
    const images = ( _embedded['wp:featuredmedia'] ) ? _embedded['wp:featuredmedia'] : [];

    return  (
        <>
            <div className="motd-title">{item.title.rendered}</div>
            <div className="motd-image-wrapper"><GetFeaturedImage {...images}/></div>     
            <div className="motd-message " dangerouslySetInnerHTML={{__html:item.content.rendered}}></div>  
        {
        //     <div>{onlyWidth} - {onlyHeight}</div>
        }
        </>
    )
}
