import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import { Home } from './pages/Home';
import { MessageOfTheDay } from './pages/MessageOfTheDay';

const App = () => {

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/motd">
          <MessageOfTheDay />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
