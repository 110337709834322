import React from 'react';
import { useWindowSize, useWindowWidth, useWindowHeight } from '@react-hook/window-size'

export const GetFeaturedImage = ( props ) => {

    const {images, width} = props;

    if( images && ("0" in images) ) {
        const { source_url } = images['0'];

        return (
            <img 
                src={source_url} 
                className="motd-image-only"
                alt="Message of the day"
                width={width}
            />
        )
    } else {
        return null
    }
}

export const ImageOnlyTemplate = (item) => {
    const onlyHeight = useWindowHeight();
    const onlyWidth = useWindowWidth();

    const { _embedded } = item;
    const images = ( _embedded['wp:featuredmedia'] ) ? _embedded['wp:featuredmedia'] : [];

    return  (
        <>
            <div className="motd-image-wrapper"><GetFeaturedImage images={images} width={onlyWidth}/></div>     
        </>
    )
}
