import React from 'react';

export const Home = () => {

    return (
        <>
            Hello.
        </>
    )

}