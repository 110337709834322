import React from 'react';

export const TextOnlyTemplate = (props) => {
    
    return  (
    <>
        TextOnly
    </>
    )
}
