import React from 'react';
import { MessageOfTheDayList } from './components/MessageOfTheDayList';

export const MessageOfTheDay = () => {

    return (
        <div className="motd-wrapper">
            <MessageOfTheDayList/>
        </div>
    )

}