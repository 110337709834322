import React  from 'react';
import { ImageAndTextTemplate } from './ImageAndTextTemplate';
import { TextOnlyTemplate } from './TextOnlyTemplate';
import { ImageOnlyTemplate } from './ImageOnlyTemplate';

export const MotdTemplate = (item) => {
    
    //const { acf: { motd_template } = { motd_template: null } } = item;

    const motd_template = "imageonly"; //hard set for this now
    const components = {
        imageandtext: ImageAndTextTemplate,
        textonly: TextOnlyTemplate,
        imageonly: ImageOnlyTemplate
    };

    const TemplateName = components[motd_template || 'textonly'];
    
    return  (<TemplateName {...item} />)

}
