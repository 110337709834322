import React, {useState, useEffect} from 'react';
import { MotdTemplate } from './MotdTemplate';

export const MessageOfTheDayList = () => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
  
    useEffect(() => {
      fetch("https://tonestone.com/wp-json/wp/v2/motd?_embed=1")
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setItems(result);
            console.log("results:", result);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }, [])
  
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <>
          {
            (isLoaded) ?
              items.map(item => (
                <MotdTemplate {...item} key={item.id}/>
              ))
            : ""
          }
        </>
      );
    }
  }